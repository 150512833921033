// config module
export const IS_LOADING = 'IS_LOADING'

// messages module
export const CURRENT_MSG_PAGE_VIEW = 'CURRENT_MSG_PAGE_VIEW'
export const CURRENT_MSG_INFO = 'CURRENT_MSG_INFO'

// billing module
export const CURRENT_BILLING_PAGE_VIEW = 'CURRENT_BILLING_PAGE_VIEW'
export const BILLING_BALANCE_DUE = 'BILLING_BALANCE_DUE'

// PROFILE MODULE
export const CURRENT_PROFILE_PAGE_VIEW = 'CURRENT_PROFILE_PAGE_VIEW'
export const PROFILE_AUTH_INFO = 'PROFILE_AUTH_INFO'
export const PROFILE_MY_INFO = 'PROFILE_MY_INFO'
export const PROFILE_PATIENT_ASSOCIATED = 'PROFILE_PATIENT_ASSOCIATED'
export const CURRENT_PROFILE_PAYMENT_VIEW = 'CURRENT_PROFILE_PAYMENT_VIEW'
export const CURRENT_PROFILE_PATIENT = 'CURRENT_PROFILE_PATIENT'
export const PROFILE_FACILITY_INFO = 'PROFILE_FACILITY_INFO'

// task module
export const TASK_CURRENT_TASK_TYPE = 'TASK_CURRENT_TASK_TYPE'
export const TASK_CURRENT_CASE_HISTORY_ID = 'TASK_CURRENT_CASE_HISTORY_ID'
export const TASK_CURRENT_CASE_HISTORY_PROBLEMS =
  'TASK_CURRENT_CASE_HISTORY_PROBLEMS'
export const TASK_CURRENT_CASE_HISTORY_OATS = 'TASK_CURRENT_CASE_HISTORY_OATS'
export const TASK_CURRENT_CASE_HISTORY_COMPLAINT_FORM_VAL =
  'TASK_CURRENT_CASE_HISTORY_COMPLAINT_FORM_VAL'
export const TASK_CURRENT_CASE_HISTORY_AUTO_ACCIDENT =
  'TASK_CURRENT_CASE_HISTORY_AUTO_ACCIDENT'
export const TASK_CURRENT_CASE_HISTORY_AA_FORM_VALUES =
  'TASK_CURRENT_CASE_HISTORY_AA_FORM_VALUES'
export const TASK_CURRENT_OFFICE_NAME = 'TASK_CURRENT_OFFICE_NAME'
export const TASK_CURRENT_CODE = 'TASK_CURRENT_CODE'
export const TASK_IS_ACCESS_ONLY = 'TASK_IS_ACCESS_ONLY'
export const TASK_IS_ACCESS_ONLY_ROUTE = 'TASK_IS_ACCESS_ONLY_ROUTE'
export const TASK_INFO = 'TASK_INFO'
export const TASK_ITEM = 'TASK_ITEM'

// billing module
export const BILLING_TASK_STATEMENT = 'BILLING_TASK_STATEMENT'

// fortis payment
export const FORTIS_PAYMENT_FORM = 'FORTIS_PAYMENT_FORM'
export const FORTIS_STATEMENT_INFO = 'FORTIS_STATEMENT_INFO'
export const FORTIS_PAYMENT_VIA_URL = 'FORTIS_PAYMENT_VIA_URL'
